import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { compact, uniqBy } from 'lodash'
import React, { memo } from 'react'

import { MenuItem, Props as MenuItemProps } from './MenuItem'

export interface Props {
  languageCode: string
  policyMenu?: { [key: string]: MenuItemProps[] }[]
  siteName?: string
  vat?: string
}

export const Bottom = memo(function Bottom({
  languageCode,
  policyMenu,
  siteName,
  vat,
}: Props) {
  const policyNav = compact(
    compact(policyMenu?.map((lang) => lang[languageCode as 'it-IT']))[0],
  ).filter((t) => t.languageCode === languageCode)

  return (
    <Container>
      {policyMenu ? (
        <Policy dial={2} row wrap>
          <>
            {uniqBy(policyNav, 'title').map((item, index) => (
              <MenuItem key={index} {...item} />
            ))}
            <MenuItem
              title={useVocabularyData('credits', languageCode)}
              URL="https://www.qnt.it"
            />
          </>
        </Policy>
      ) : null}
      {siteName ? <SiteName>{siteName}</SiteName> : null}
      {vat ? (
        <Vat> - {`${useVocabularyData('vat', languageCode)} ${vat}`}</Vat>
      ) : null}
    </Container>
  )
})

const Container = styled.div`
  display: inline-block;
  background: ${({ theme }) => theme.colors.variants.neutralDark3};
  color: ${({ theme }) => theme.colors.variants.neutralLight3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  margin: 4.25rem auto 0;
  padding: 0 10.417vw;
  position: relative;

  @media (max-width: 767px) {
    padding: 0;
  }
`

const Policy = styled(FlexBox)`
  margin-bottom: 0.375rem;
`

const SiteName = styled.span`
  font-size: 0.875rem;
  line-height: 2rem;
`

const Vat = styled.span`
  font-size: 0.875rem;
  line-height: 2rem;
`
