import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme/index'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

export interface Props {
  languageCode: string
  onClick: () => void
  variant?: Variant
}

export const ModalClose = memo(function ModalClose({
  languageCode,
  onClick,
  variant = 'default',
}: Props) {
  return (
    <Container dial={5} row onClick={onClick} variant={variant}>
      <Icon className="close-icon" />
      {variant !== 'compact' ? (
        <Label>{useVocabularyData('close', languageCode)}</Label>
      ) : null}
    </Container>
  )
})

const Container = styled(FlexBox)<ContainerProps>`
  cursor: pointer;
  position: fixed;
  top: 3.25rem;
  left: 3.75vw;
  transition: 0.6s ease-in-out;
  z-index: 3;
  &:hover {
    .close-icon {
      &:before,
      &:after {
        transform: none;
      }
    }
  }

  ${({ variant }) => {
    switch (variant) {
      case 'compact':
        return css`
          top: 1.75rem;
          right: 1.75rem;
          left: auto;
          .close-icon {
            &:before,
            &:after {
              background: ${theme.colors.variants.neutralDark2};
            }
          }
        `
      case 'right':
        return css`
          right: 3.333vw;
          left: auto;
          > div {
            &:first-of-type {
              order: 2;
            }
            &:last-of-type {
              margin-right: 1.375rem;
              margin-left: auto;
            }
          }
        `
    }
  }}

  @media (max-width: 1023px) {
    top: 2.25rem;
    left: 1.875rem;

    ${({ variant }) => {
      switch (variant) {
        case 'compact':
          return css`
            top: 1.75rem;
            right: 1.75rem;
            left: auto;
          `
        case 'right':
          return css`
            right: 30px;
            left: auto;
          `
      }
    }}
  }
`

const Icon = styled.div`
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  &:before,
  &:after {
    content: '';
    width: 1.875rem;
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    border-radius: 1.25rem;
    position: absolute;
    top: 0.6875rem;
    left: -0.25rem;
    transition: 0.2s ease-out;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.0875rem;
  line-height: 1.25rem;
  margin-left: 1.375rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    display: none !important;
  }
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'compact' | 'right'
