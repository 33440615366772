import styled from '@emotion/styled'
import React, { memo } from 'react'

import { Bottom } from './Bottom'
import { Contacts } from './Contacts'
import { Props as MenuItemProps } from './MenuItem'
import { Social } from './Social'

export interface Props {
  address?: string
  email?: string
  email_2?: string
  facebookURL?: string
  IBEID?: number
  instagramURL?: string
  languageCode: string
  languagePrefix?: string | null
  logo?: string
  phone?: string
  policyMenu?: { [key: string]: MenuItemProps[] }[]
  siteName?: string
  vat?: string
  winterPhone?: string
  winterPhone2?: string
}

export const Footer = memo(function Footer({
  address,
  email,
  email_2,
  facebookURL,
  IBEID,
  instagramURL,
  languageCode,
  languagePrefix,
  logo,
  phone,
  policyMenu,
  siteName,
  vat,
  winterPhone,
  winterPhone2,
}: Props) {
  return (
    <Container>
      <Wrapper>
        {logo ? (
          <Brand>
            <Logo src={logo} alt={siteName} width="180" height="46" />
          </Brand>
        ) : null}
        {siteName ? <SiteName>{siteName}</SiteName> : null}
        {address ? <Address>{address}</Address> : null}

        <Contacts
          email={email}
          email_2={email_2}
          IBEID={IBEID}
          languageCode={languageCode}
          languagePrefix={languagePrefix}
          phone={phone}
          winterPhone={winterPhone}
          winterPhone2={winterPhone2}
        />

        <Social instagramURL={instagramURL} facebookURL={facebookURL} />

        <Bottom
          languageCode={languageCode}
          policyMenu={policyMenu}
          siteName={siteName}
          vat={vat}
        />
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralDark3};
  padding: 4.6875rem 6.667vw;
  text-align: center;

  @media (max-width: 767px) {
    padding: 3.75rem 1.875rem;
  }
`

const Wrapper = styled.div`
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: calc(100% - 2.875rem);
    border: 0.0625rem solid ${({ theme }) => theme.colors.variants.neutralDark2};
    position: absolute;
    top: 1.4375rem;
    left: 0;
  }

  @media (max-width: 767px) {
    &:before {
      display: none;
    }
  }
`

const Brand = styled.div`
  display: inline-block;
  background: ${({ theme }) => theme.colors.variants.neutralDark3};
  margin: 0 auto 3.9375rem;
  padding: 0 4.861vw;
  position: relative;

  @media (max-width: 767px) {
    padding: 0;
  }
`

const Logo = styled.img``

const SiteName = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.25rem;
  line-height: 1.6875rem;
  margin-bottom: 0.25rem;
`

const Address = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryDark1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.125rem;
  line-height: 2rem;

  @media (max-width: 1023px) {
    font-size: 1rem;
    line-height: 1.75rem;
  }
`
