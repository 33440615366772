import * as React from 'react'
import { memo } from 'react'

const SvgChevronsDown = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 12.828 14.414"
  >
    <path d="m1.414 8.414 5 5 5-5M1.414 1.414l5 5 5-5" />
  </svg>
)

const Memo = memo(SvgChevronsDown)
export default Memo
