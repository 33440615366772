import * as React from 'react'
import { memo } from 'react'

const SvgMinus = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
  >
    <path
      data-name="Linea 8"
      fill="none"
      stroke="#568d86"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M1 8h14"
    />
  </svg>
)

const Memo = memo(SvgMinus)
export default Memo
