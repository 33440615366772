import * as React from 'react'
import { memo } from 'react'

const SvgGift = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 101.635 102"
  >
    <path d="M90.651 50.153V101H10.984V50.153" />
    <path d="M1 25.95h99.635v25.331H1zM50.818 100.814V24.822" />
    <path d="M51.662 26.423H28.781a12.712 12.712 0 1 1 0-25.423c17.796 0 22.881 25.423 22.881 25.423Z" />
    <path d="M49.974 26.423h22.881a12.712 12.712 0 1 0 0-25.423C55.059 1 49.974 26.423 49.974 26.423Z" />
  </svg>
)

const Memo = memo(SvgGift)
export default Memo
