import {
  Header as HeaderComponent,
  Variant,
} from 'app/components/Common/Header'
import { Props as MenuItemProps } from 'app/components/Common/Header/MenuItem'
import { graphql, StaticQuery } from 'gatsby'
import React, { memo } from 'react'
import { createContext } from 'react'

import { getHeaderProps } from './getHeaderProps'

export interface Props {
  email?: string
  IBEID?: number
  languageCode?: string
  languageName?: string
  languagePrefix?: string | null
  logo?: string
  menuItems: { [key: string]: MenuItemProps[] }[]
  pageID?: string
  phone?: string
  siteName?: string
  variant?: Variant
}

export const HeaderContext = createContext({})

export const Header = memo(function Header({
  email,
  IBEID,
  languageCode,
  languagePrefix,
  logo,
  menuItems,
  pageID,
  phone,
  siteName,
  variant = 'default',
}: Props) {
  return (
    <StaticQuery
      query={graphql`
        query HeaderData {
          allSitePage {
            nodes {
              pageContext
              path
            }
          }
        }
      `}
      render={(data) => {
        const props = getHeaderProps(
          data,
          languageCode || 'it-IT',
          menuItems,
          pageID,
        )

        return props ? (
          <HeaderContext.Provider
            value={{
              email,
              IBEID,
              languageCode,
              languagePrefix,
              logo,
              phone,
              siteName,
              variant,
            }}
          >
            <HeaderComponent {...props} />
          </HeaderContext.Provider>
        ) : null
      }}
    />
  )
})
