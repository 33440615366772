import * as React from 'react'
import { memo } from 'react'

const SvgTriangleDown = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 8 6"
  >
    <path d="M4 6 0 0h8Z" />
  </svg>
)

const Memo = memo(SvgTriangleDown)
export default Memo
