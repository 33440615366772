import styled from '@emotion/styled'
import { Language, TriangleDown } from 'app/components/Icons'
import { Link } from 'gatsby'
import React, { memo } from 'react'

export interface Props {
  className?: string
  code: string
  pageID?: string
  label: string
  languagePrefix: string | null
  URL: string
}

export const LanguageItem = memo(function LanguageItem({
  className,
  code,
  label,
  languagePrefix,
  URL,
}: Props) {
  return (
    <Anchor
      className={className}
      to={URL}
      lang={languagePrefix === code ? undefined : code}
    >
      <Language />
      <Label>{label}</Label>
      <TriangleDown />
    </Anchor>
  )
})

const Anchor = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.0875rem;
  line-height: 1.25rem;
  transition: 0.3s ease-in-out;
  &:hover {
    text-decoration: underline;
  }
  &.active {
    background: none;
    color: ${({ theme }) => theme.colors.variants.neutralLight4};
    pointer-events: none;
    text-transform: uppercase;

    svg {
      display: block;
      transition: 0.3s ease-in-out;
    }
  }

  svg {
    display: none;
    width: auto;
    &:first-of-type {
      height: 1.25rem;
      margin-right: 1rem;
      fill: none;
      stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
      stroke-width: 2;
    }
    &:last-of-type {
      height: 0.4375rem;
      margin-left: 0.5rem;
      fill: ${({ theme }) => theme.colors.variants.neutralLight4};
    }
  }

  @media (max-width: 767px) {
    &.active {
      svg {
        &:first-of-type {
          display: none;
        }
      }
    }
  }
`

const Label = styled.span``
