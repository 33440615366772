import * as React from 'react'
import { memo } from 'react'

const SvgCheckCircle = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 32.743 32.366"
  >
    <path d="M31.329 14.795v1.395a15.163 15.163 0 1 1-8.992-13.859" />
    <path d="M31.329 4.06 16.166 19.238l-4.549-4.549" />
  </svg>
)

const Memo = memo(SvgCheckCircle)
export default Memo
