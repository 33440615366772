import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo, useEffect } from 'react'

export interface Props {
  IBEID?: number
  languageCode: string
  languagePrefix: string
}

export const Syncrobox = memo(function Syncrobox({
  IBEID = 1,
  languageCode,
  languagePrefix,
}: Props) {
  useEffect(() => {
    const script = document.createElement('script')
    script.async = true

    script.innerHTML = `
    (function (i, s, o, g, r, a, m) {
        i['SBSyncroBoxParam'] = r; i[r] = i[r] || function () {
            (i[r].q = i[r].q || []).push(arguments)
        }, i[r].l = 1 * new Date(); a = s.createElement(o),
        m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m)
    })(window, document, 'script', 'https://cdn.simplebooking.it/search-box-script.axd?IDA=${IBEID}','SBSyncroBox');
    SBSyncroBox({
        CodLang: '${languagePrefix.toLocaleUpperCase()}',
        NumberOfMonthsVertical: '2',
        
        Styles: {
          Theme: 'light-pink',
          CustomBGColor: 'transparent',
          CustomButtonBGColor: '${theme.colors.variants.neutralDark4}',
          CustomButtonHoverBGColor: '${theme.colors.variants.neutralDark4}',
          CustomFieldBackgroundColor: 'transparent',
          CustomLinkColor: '${theme.colors.variants.neutralLight4}',
          CustomIconColor: '${theme.colors.variants.primaryDark1}',
          CustomBoxShadowColor: 'transparent',
          CustomBoxShadowColorFocus: 'transparent',
          CustomBoxShadowColorHover: 'transparent',
          CustomAccentColor: '${theme.colors.variants.primaryDark1}',
          CustomAccentColorHover: '${theme.colors.variants.primaryDark1}',
          CustomColorHover: '${theme.colors.variants.neutralDark2}',
          CustomLabelColor: '${theme.colors.variants.neutralLight4}',
          CustomLabelHoverColor: '${theme.colors.variants.neutralDark3}',
          CustomCalendarBackgroundColor: '${
            theme.colors.variants.neutralLight3
          }',
          CustomWidgetBGColor: '${theme.colors.variants.neutralLight3}',
          CustomWidgetElementHoverBGColor: '${
            theme.colors.variants.neutralDark4
          }'
        },

        Labels: {
          CheckinDate: {'EN': 'Check in', 'IT': 'Check in'},
          CheckoutDate: {'EN': 'Check out', 'IT': 'Check out'},
          NumPersons: {'EN': 'Guests','IT': 'Ospiti'},
          NumAdults: {'EN': 'Adults', 'IT': 'Adulti'},
          NumKids: {'EN': 'Kids', 'IT': 'Bambini'},
          PromoCode: {'EN': 'Promo Code', 'IT': 'Codice Promo'},
          ModCancReservation: {'EN': 'Cancel Reservation', 'IT': 'Cancella Prenotazione'},
          CheckAvailability: {'EN': 'Book now', 'IT': 'Prenota'},
        }
        
    });
    `

    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <Container dial={5} row space="between">
      <Title
        dangerouslySetInnerHTML={{
          __html: useVocabularyData('ibe-title', languageCode),
        }}
      />
      <Widget id="sb-container" />
    </Container>
  )
})
const Container = styled(FlexBox)`
  height: 8.125rem;
  background: ${({ theme }) => theme.colors.variants.primaryDark1};
  position: absolute;
  top: calc(100vh - 8.125rem);
  right: 0;
  left: 0;

  #sb-container {
    .sb-custom-link-color {
      &:hover {
        color: ${({ theme }) => theme.colors.variants.neutralLight4} !important;
      }
    }
    .sb__guests-children-age-label {
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors.variants.neutralDark5} !important;
    }
    .sb__guests-children-age-select {
      background: ${({ theme }) =>
        theme.colors.variants.neutralLight4} !important;
      color: ${({ theme }) => theme.colors.variants.neutralDark5} !important;
    }
    .sb__footer-promo-label {
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors.variants.neutralDark5} !important;
    }
    .sb [type='text'] {
      height: 36px;
      background: ${({ theme }) =>
        theme.colors.variants.neutralLight4} !important;
      border-radius: 0;
      box-shadow: none !important;
      color: ${({ theme }) => theme.colors.variants.neutralDark5} !important;
      padding: 0 0.75rem;
    }
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  flex: 1;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.25rem;
  line-height: 1.6875rem;
  padding: 0 3.333vw;
`

const Widget = styled.div`
  .sb {
    display: flex !important;
    font-family: ${({ theme }) => theme.fontFamily.paragraph};
    padding: 0 !important;
    position: relative;
  }
  .sb__form {
    align-items: center;
  }
  .sb__property {
    display: none;
  }
  .sb button,
  .sb input {
    font-family: ${({ theme }) => theme.fontFamily.paragraph};
  }
  .sb__btn {
    border-radius: 0;
  }
  .sb select {
    box-shadow: none !important;
    border-radius: 0;
    padding: 0.125rem 0.75rem 0.25rem 1.125rem;
  }
  .sb__form-field-label {
    height: auto;
    font-size: 0.875rem;
    font-weight: 700;
    letter-spacing: 0.0875rem;
    line-height: 1.25rem;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
  }
  .sb__form-field {
    min-width: 13.611vw;
    border-bottom: 1px solid
      ${({ theme }) => theme.colors.variants.neutralLight4};
    cursor: pointer;
    margin-right: 2.083vw;
    padding-bottom: 0.1875rem;
  }
  .sb__dates {
    display: flex;
    width: auto !important;
  }
  .sb__form-field-date-number {
    color: ${({ theme }) => theme.colors.variants.neutralLight4};
    margin-right: 0.25rem;
    font-size: 1rem !important;
    line-height: 2rem !important;
    font-weight: 300;
  }
  .sb__form-field-weekday,
  .sb__form-field-rooms {
    display: none;
  }
  .sb__form-field-month-year,
  .sb__form-field-guests {
    color: ${({ theme }) => theme.colors.variants.neutralLight4};
    font-size: 1rem !important;
    font-weight: 300;
    line-height: 2rem !important;
    text-transform: capitalize;
  }
  .sb__form-field-input {
    height: auto !important;
    border-radius: 0;
    box-shadow: none;
    background: transparent;
    padding: 0 !important;
    &:hover {
      box-shadow: none;
    }
  }
  .sb__guests-rooms {
    width: auto !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  .sb__form-field-icon {
    transform: translateY(0.875rem);
    &:before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-left: 0.3125rem solid transparent;
      border-right: 0.3125rem solid transparent;
      border-top: 0.4375rem solid
        ${({ theme }) => theme.colors.variants.neutralLight4};
    }
    .icon {
      display: none;
    }
  }
  .sb__guests {
    box-shadow: none !important;
    border-radius: 0;
    margin-top: 1.25rem;
    left: 0 !important;
  }
  .sb__guests-room-header {
    margin-bottom: 0.75rem;
  }
  .sb__guests-room-remove {
    .icon {
      width: 2.5rem;
      height: 2.5rem;
      margin-top: -0.375rem;
    }
  }
  .sb__btn--verify {
    width: 15.972vw !important;
    height: 8.125rem !important;
    font-size: 0.875rem !important;
    font-weight: 700;
    letter-spacing: 0.0875rem;
    line-height: 1.25rem;
    border-radius: 0;
    padding: 0 !important;
    margin-top: 0;
    margin-left: 17.361vw;
    text-align: center;
    text-transform: uppercase;
  }
  .sb__footer {
    display: flex;
    align-items: center;
    width: 11.111vw;
    height: 8.125rem;
    position: absolute;
    top: 0;
    right: 19.444vw;
  }
  .sb__footer-actions {
    float: none !important;
    width: auto !important;
  }
  .sb__footer-promo {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    margin-bottom: 1.25rem;
    left: 0 !important;
  }
  .sb__footer-promo-wrapper {
    float: none !important;
  }
  .sb__footer-link {
    width: auto !important;
    background-repeat: no-repeat;
    background-position: 0 2px;
    float: none !important;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 2rem;
    margin: 0 !important;
    padding-left: 1.375rem;
    transition: 0.3s ease-in-out;
    text-align: left;
    &.sb__footer-link--promo {
      background-image: url('/tag.svg');
    }
    &.sb__footer-link--edit {
      background-image: url('/trash.svg');
    }
    .icon {
      display: none;
    }
  }

  .sb__calendar {
    box-shadow: none;
    border-radius: 0;
    margin-top: 1.25rem;
    z-index: 3;
  }
  .sb__calendar-month-name {
    font-size: 1rem;
    text-transform: capitalize;
  }
  .sb__calendar-btn {
    box-shadow: none !important;
    border-radius: 0;
  }
  .sb__calendar-btn-icon {
    width: 24px;
    height: 25px;
    .icon {
      width: 44px;
      height: 44px;
      margin: -10px 0 0 -9px;
    }
  }
  .sb__calendar-day {
    border-radius: 0;
  }

  .number-of-months-vertical--right .sb__calendar {
    right: initial;
  }
  .sb-open-top .sb__calendar,
  .sb-open-top .sb__guests {
    margin-bottom: 1.25rem;
  }

  @media (max-width: 1399px) {
    .sb__footer {
      display: none;
    }
    .sb__dates {
      min-width: 40vw;
    }
    .sb__btn--verify {
      margin-left: 2.083vw;
    }
  }
`
