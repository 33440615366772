import * as React from 'react'
import { memo } from 'react'

const SvgExpand = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 19.868 20"
  >
    <path d="M19.868 5.519V0h-5.519v1.474h3L9.867 8.957 2.516 1.606h3V.132H0v5.519h1.474v-3L8.826 10l-7.352 7.352v-3H0v5.519h5.519v-1.477h-3l7.351-7.351 7.483 7.483h-3V20h5.519v-5.519h-1.478v3L10.911 10l7.482-7.483v3h1.475" />
  </svg>
)

const Memo = memo(SvgExpand)
export default Memo
