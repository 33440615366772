import styled from '@emotion/styled'
import { IBEAnchor } from 'app/components/Common/Button/IBEAnchor'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { HeaderContext } from 'app/containers/Header'
import { theme } from 'app/theme'
import { useVocabularyData } from 'app/utils/vocabulary'
import { rgba } from 'emotion-rgba'
import React, { memo, useContext, useEffect, useState } from 'react'

export const Toolbars = memo(function Toolbars() {
  const { email, IBEID, languageCode, languagePrefix, phone } = useContext(
    HeaderContext,
  ) as any

  const [scrollDir, setScrollDir] = useState('up')

  useEffect(() => {
    const threshold = 0
    let lastScrollY = window.pageYOffset
    let ticking = false

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false
        return
      }
      setScrollDir(scrollY > lastScrollY ? 'down' : 'up')
      lastScrollY = scrollY > 0 ? scrollY : 0
      ticking = false
    }

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir)
        ticking = true
      }
    }

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [scrollDir])

  return (
    <Container
      className={scrollDir == 'down' ? 'hidden' : ''}
      dial={5}
      row
      stretch
    >
      {phone ? (
        <Item
          href={`tel:${phone}`}
          onClick={() => {
            typeof window.gtag !== 'undefined' &&
              window.gtag('event', 'click', {
                event_category: 'Website',
                event_label: 'Phone Number',
              })
          }}
        >
          {useVocabularyData('telephone', languageCode)}
        </Item>
      ) : null}
      {email ? (
        <Item
          href={`mailto:${email}`}
          onClick={() => {
            typeof window.gtag !== 'undefined' &&
              window.gtag('event', 'click', {
                event_category: 'Website',
                event_label: 'Email Address',
              })
          }}
        >
          {useVocabularyData('email', languageCode)}
        </Item>
      ) : null}
      {IBEID ? (
        <IBEAnchor
          IBEID={IBEID}
          label="book"
          languageCode={languageCode}
          languagePrefix={languagePrefix}
        />
      ) : null}
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 100%;
  box-shadow: ${rgba(theme.colors.variants.neutralDark1, 0.12)} 0 2px 8px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 50;
  text-transform: uppercase;
  transition: 0.3s ease-out;
  &.hidden {
    transform: translateY(6rem);
  }

  a {
    width: 33.333%;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    border-right: 1px solid
      ${({ theme }) => theme.colors.variants.neutralLight2};
    color: ${({ theme }) => theme.colors.variants.primaryDark1};
    font-family: ${({ theme }) => theme.fontFamily.paragraph};
    font-size: 0.75rem;
    font-weight: 700;
    letter-spacing: 0.0875rem;
    line-height: 1.25rem;
    padding: 1.0625rem 0;
    text-align: center;
    &:last-of-type {
      background: ${({ theme }) => theme.colors.variants.primaryDark1};
      border-right: 0;
      color: ${({ theme }) => theme.colors.variants.neutralLight4};
    }
  }
`

const Item = styled.a``
