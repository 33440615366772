import * as React from 'react'
import { memo } from 'react'

const SvgLanguage = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 22 22"
  >
    <g transform="translate(-1 -1)">
      <circle cx={10} cy={10} r={10} transform="translate(2 2)" />
      <path d="M2 12h20M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10Z" />
    </g>
  </svg>
)

const Memo = memo(SvgLanguage)
export default Memo
