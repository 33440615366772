import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { theme } from 'app/theme/index'
import { Link } from 'gatsby'
import React, { memo } from 'react'

export interface Props {
  id?: string
  title: string
  languageCode?: string
  languagePrefix?: string | null
  URL: string
}

export const MenuItem = memo(function MenuItem({ title, URL }: Props) {
  if (!title) {
    return null
  }

  const EXTERNAL_URL_REGEX = new RegExp('^(https?|ftp|www)://')
  const externalURL = URL && EXTERNAL_URL_REGEX.test(URL)

  return (
    <>
      {externalURL ? (
        <Anchor href={URL} rel="noopener" target="_blank">
          {title}
        </Anchor>
      ) : (
        <Container activeClassName="active" to={URL}>
          {title}
        </Container>
      )}
    </>
  )
})

const Style = css`
  display: flex;
  align-items: center;
  color: ${theme.colors.variants.neutralLight4};
  font-family: ${theme.fontFamily.paragraph};
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.0875rem;
  line-height: 1.25rem;
  text-transform: uppercase;
  transition: 0.3s ease-in-out;
  &:hover {
    color: ${theme.colors.variants.primaryDark1};
  }
  &:last-of-type {
    &:after {
      display: none;
    }
  }
  &:after {
    content: '';
    width: 0.125rem;
    height: 0.875rem;
    background: ${theme.colors.variants.neutralLight4};
    margin: 0 1.375rem;
  }

  @media (max-width: 424px) {
    width: 100%;
    justify-content: center;
    margin-top: 0.75rem;
    &:first-of-type {
      margin-top: 0;
    }
    &:after {
      display: none;
    }
  }
`

const Anchor = styled.a`
  ${Style}
`

const Container = styled(Link)`
  ${Style}
`
