import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ChevronRight } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme/index'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { motion } from 'framer-motion'
import { Link } from 'gatsby'
import React, { memo, useEffect, useRef, useState } from 'react'

export interface Props {
  childs?: ChildsProps[]
  className?: string
  id?: string
  image?: string
  title: string
  languageCode?: string
  languagePrefix?: string | null
  url?: string
}

interface ChildsProps {
  id?: string
  title: string
  url: string
}

export const MenuItem = memo(function MenuItem({
  childs,
  className,
  title,
  url,
}: Props) {
  if (!title) {
    return null
  }

  const variants = {
    visible: (i: any) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.1,
        stiffness: 1000,
        velocity: 0,
      },
    }),
    hidden: { opacity: 0, y: -30 },
  }

  const [submenuVisibility, setSubmenuVisibility] = useState(false)
  const activeItem = useRef(null)

  const handleClickOutside = (e: any) => {
    // @ts-ignore
    if (activeItem.current && !activeItem.current.contains(e.target)) {
      setSubmenuVisibility(false)
    } else {
      setSubmenuVisibility(true)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [submenuVisibility])

  const submenuDOM =
    childs && childs.length > 0 ? (
      <Submenu className={`submenu${submenuVisibility ? ' open' : ''}`}>
        {childs
          .sort((a: any, b: any) => a.weight - b.weight)
          .map((item, index) => (
            <motion.li
              key={index}
              custom={index}
              animate={submenuVisibility ? 'visible' : 'hidden'}
              variants={variants}
            >
              <ChildItem key={index} {...item} />
            </motion.li>
          ))}
      </Submenu>
    ) : null

  return (
    <>
      {url ? (
        <Anchor className={className} to={url} activeClassName="active">
          <Label className="item-label" inline>
            {title}
          </Label>
          {submenuDOM}
        </Anchor>
      ) : (
        <NoLink
          className={submenuVisibility ? 'open' : undefined}
          ref={activeItem}
        >
          <Label dial={4} className="item-label" inline row>
            <Fade className="item-fade">{title}</Fade>
            <style type="text/css">{mediaStyle}</style>
            <MediaContextProvider>
              <Media lessThan="ipadHorizontal">
                <ChevronRight />
              </Media>
            </MediaContextProvider>
          </Label>
          {submenuDOM}
        </NoLink>
      )}
    </>
  )
})

export const ChildItem = memo(function ChildItem({ title, url }: ChildsProps) {
  return (
    <Anchor to={url} activeClassName="active">
      {title}
    </Anchor>
  )
})

const Style = css`
  color: ${theme.colors.variants.neutralLight4};
  font-family: ${theme.fontFamily.heading};
  font-size: 2.2vh;
  font-weight: 400;
  line-height: 3vh;
  transition: 0.2s ease-out;

  @media (max-width: 1023px) {
    font-size: 1.25rem;
    line-height: 1.6875rem;
  }
`

const Anchor = styled(Link)`
  ${Style}

  &.active,
  &:hover {
    color: ${theme.colors.variants.neutralDark3};
  }
`

const NoLink = styled.span`
  display: flex;
  margin-left: -0.75rem;
  &.open {
    color: ${({ theme }) => theme.colors.variants.neutralLight4} !important;
    .item-label {
      &:after {
        flex: 1;
      }
    }
    .item-fade {
      &:hover {
        color: ${({ theme }) => theme.colors.variants.neutralLight4} !important;
      }
      &:before {
        right: -0.75rem;
      }
    }
    .submenu {
      opacity: 1;
      transition: 0.3s 0.15s ease-in-out;
      transform: translate(0, -50%);
      visibility: visible;
    }
  }

  .item-label {
    width: calc(36% + 0.75rem);
    &:after {
      content: '';
      background: ${theme.colors.variants.neutralLight4};
      flex: 0;
      height: 0.125rem;
      margin-right: -2.083vw;
      margin-left: 2.917vw;
      transform: translateY(-50%);
      transition: 0.3s ease-in-out;
    }
  }

  ${Style}

  @media (max-width: 1023px) {
    flex-wrap: wrap;
    &.open {
      svg {
        transform: rotate(-90deg) translateX(-0.125rem);
      }
    }
    .item-label {
      width: auto;
      margin-right: 0;
      margin-left: 0;
      &:after {
        display: none;
      }
    }
    svg {
      width: 0.75rem;
      height: auto;
      fill: ${({ theme }) => theme.colors.variants.neutralDark3};
      margin-left: 1rem;
      transform: rotate(90deg) translateX(-0.125rem);
    }
  }
`

const Label = styled(FlexBox)`
  position: relative;
  z-index: 2;
`

const Fade = styled.span`
  cursor: pointer;
  padding-left: 0.75rem;
  position: relative;
  transition: 0.2s ease-out;
  &:before {
    content: '';
    background: ${theme.colors.variants.neutralDark4};
    position: absolute;
    top: -0.5rem;
    right: 100%;
    bottom: -0.25rem;
    left: 0;
    transition: 0.3s ease-in-out;
    z-index: -1;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.variants.neutralDark3};
  }

  @media (max-width: 1023px) {
    &:before {
      display: none;
    }
  }
`

const Submenu = styled.ul`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 55%;
  height: 100%;
  min-height: 54vh;
  background: ${({ theme }) => theme.colors.variants.neutralDark3};
  padding: 2.9375rem 3.472vw 3.75rem;
  opacity: 0;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-2.5rem, -50%);
  transition: 0.1s ease-in-out;
  visibility: hidden;

  li {
    margin-top: 1rem !important;
    a {
      font-family: ${({ theme }) => theme.fontFamily.paragraph};
      font-size: 2vh;
      line-height: 3.5vh;
      padding-bottom: 0.3125rem;
      position: relative;
      &:after {
        content: '';
        height: 0.125rem;
        background: ${({ theme }) => theme.colors.variants.neutralLight4};
        opacity: 0.2;
        position: absolute;
        bottom: -0.1875rem;
        right: 100%;
        left: 0;
        transition: 0.3s ease-in-out;
      }
      &.active,
      &:hover {
        color: ${({ theme }) => theme.colors.variants.neutralLight4};
        &.active,
        &:after {
          right: 0;
        }
      }
    }
  }

  @media (max-width: 1023px) {
    width: 100%;
    height: auto;
    max-height: 0;
    min-height: 0;
    background: none;
    padding: 0 1.875rem;
    opacity: 1;
    overflow: hidden;
    position: relative;
    top: auto;
    right: auto;
    transform: none !important;
    transition: 0.6s ease-in-out;
    visibility: visible;
    &.open {
      max-height: 18.75rem;
      padding-bottom: 0.375rem;
    }
    li {
      opacity: 1 !important;
      transform: none !important;
      a {
        font-size: 1.125rem;
        line-height: 2rem;
      }
    }
  }
`
