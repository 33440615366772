import styled from '@emotion/styled'
import { IBEAnchor } from 'app/components/Common/Button/IBEAnchor'
import { Booking, Email, Phone } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'

export interface Props {
  email?: string
  email_2?: string
  IBEID?: number
  languageCode: string
  languagePrefix?: string | null
  phone?: string
  winterPhone?: string
  winterPhone2?: string
}

export const Contacts = memo(function Contacts({
  email,
  email_2,
  IBEID,
  languageCode,
  languagePrefix,
  phone,
  winterPhone,
  winterPhone2,
}: Props) {
  return (
    <Container dial={2} row wrap>
      <Item>
        <Phone />
        <Label>{useVocabularyData('call', languageCode)}</Label>
        {phone ? (
          <Anchor
            href={`tel:${phone}`}
            onClick={() => {
              if (isMobile) {
                typeof window.gtag !== 'undefined' &&
                  window.gtag('event', 'click', {
                    event_category: 'Website',
                    event_label: 'Phone Number',
                  })
              }
            }}
          >
            {phone}
          </Anchor>
        ) : null}
        {winterPhone ? (
          <>
            <Divider />
            <Anchor
              href={`tel:${winterPhone}`}
              onClick={() => {
                if (isMobile) {
                  typeof window.gtag !== 'undefined' &&
                    window.gtag('event', 'click', {
                      event_category: 'Website',
                      event_label: 'Phone Number',
                    })
                }
              }}
            >
              {winterPhone}
            </Anchor>
          </>
        ) : null}
        {winterPhone2 ? (
          <>
            <Divider />
            <Anchor
              href={`tel:${winterPhone2}`}
              onClick={() => {
                if (isMobile) {
                  typeof window.gtag !== 'undefined' &&
                    window.gtag('event', 'click', {
                      event_category: 'Website',
                      event_label: 'Phone Number',
                    })
                }
              }}
            >
              {winterPhone2}
            </Anchor>
          </>
        ) : null}
      </Item>
      {email || email_2 ? (
        <Item>
          <Email />
          <Label>{useVocabularyData('write', languageCode)}</Label>
          <Anchor
            href={`mailto:${email}`}
            onClick={() => {
              typeof window.gtag !== 'undefined' &&
                window.gtag('event', 'click', {
                  event_category: 'Website',
                  event_label: 'Email Address',
                })
            }}
          >
            {email}
          </Anchor>
          <Divider />
          <Anchor
            href={`mailto:${email_2}`}
            onClick={() => {
              typeof window.gtag !== 'undefined' &&
                window.gtag('event', 'click', {
                  event_category: 'Website',
                  event_label: 'Email Address',
                })
            }}
          >
            {email_2}
          </Anchor>
        </Item>
      ) : null}
      {IBEID ? (
        <Item>
          <Booking />
          <Label>{useVocabularyData('book', languageCode)}</Label>
          <IBECTA
            IBEID={IBEID}
            label="best-rate-guaranteed"
            languageCode={languageCode}
            languagePrefix={languagePrefix}
          />
        </Item>
      ) : null}
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin-top: 3.125rem;
`

const Item = styled.div`
  width: 33.333%;
  text-align: center;

  svg {
    display: inline-block;
    width: auto;
    height: 1.875rem;
    fill: ${({ theme }) => theme.colors.variants.neutralLight4};
    margin-bottom: 1rem;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-top: 1.5rem;
    &:first-of-type {
      margin-top: 0;
    }
    svg {
      display: none;
    }
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.25rem;
  line-height: 1.6875rem;

  @media (max-width: 767px) {
    font-size: 1rem;
  }
`

const Anchor = styled.a`
  color: ${({ theme }) => theme.colors.variants.primaryDark1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 2rem;
  &:hover {
    text-decoration: underline;
  }
`

const IBECTA = styled(IBEAnchor)`
  color: ${({ theme }) => theme.colors.variants.primaryDark1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 2rem;
  &:hover {
    text-decoration: underline;
  }
`

const Divider = styled.div``
