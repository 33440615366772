import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { IBEAnchor } from 'app/components/Common/Button/IBEAnchor'
import { Props as MenuItemProps } from 'app/components/Common/Header/MenuItem'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { HeaderContext } from 'app/containers/Header'
import { theme } from 'app/theme'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { useVocabularyData } from 'app/utils/vocabulary'
import disableScroll from 'disable-scroll'
import { Link } from 'gatsby'
import isbot from 'isbot'
import React, { memo, useEffect, useState } from 'react'
import { useContext } from 'react'

import { Props as LanguageItemProps } from './LanguageItem'
import { Languages } from './Languages'
import { ModalClose } from './ModalClose'
import { Navigation } from './Navigation'
import { Syncrobox } from './Syncrobox'
import { Toolbars } from './Toolbars'

export interface Props {
  languagesList: LanguageItemProps[]
  menuItems: MenuItemProps[]
  pageID?: string
}

export const Header = memo(function Header({
  languagesList,
  menuItems,
  pageID,
}: Props) {
  const { IBEID, languageCode, languagePrefix, logo, siteName, variant } =
    useContext(HeaderContext) as any

  const [scroll, setScroll] = useState(false)
  const [modalStatus, setModalStatus] = useState(false)

  useEffect(() => {
    const onScroll = () => {
      setScroll(window.scrollY > 200)
    }

    disableScroll.off()

    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Head
          className={
            scroll || variant === 'compact' || variant === 'simple'
              ? 'fixed'
              : ''
          }
          dial={4}
          row
          variant={variant}
        >
          <Hamburger
            className="hamburger-menu"
            dial={4}
            onClick={() => {
              if (!modalStatus) {
                setModalStatus(true)
                if (window.innerWidth > 1023) {
                  disableScroll.on()
                } else {
                  document.body.style.overflow = 'hidden'
                }
              } else {
                setModalStatus(false)
              }
            }}
            row
          >
            <HamburgerLines className="hamburger-lines">
              <HamburgerLine />
              <HamburgerLine />
              <HamburgerLine />
            </HamburgerLines>
            <HambugerLabel>
              {useVocabularyData('menu', languageCode)}
            </HambugerLabel>
          </Hamburger>

          {logo ? (
            <Link to={languagePrefix ? `/${languagePrefix}` : '/'}>
              <Brand className="brand">
                <Logo src={logo} alt={siteName} width="180" height="46" />
              </Brand>
            </Link>
          ) : null}

          <Languages
            className={
              scroll || variant === 'compact' || variant === 'simple'
                ? 'sticky'
                : undefined
            }
            languagesList={languagesList}
            pageID={pageID}
          />

          {(IBEID && scroll) ||
          variant === 'compact' ||
          variant === 'simple' ? (
            <IBECTA
              IBEID={IBEID}
              languageCode={languageCode}
              languagePrefix={languagePrefix}
            />
          ) : null}
        </Head>

        <Modal className={modalStatus ? 'open' : ''}>
          <ModalClose
            languageCode={languageCode}
            onClick={() => {
              if (window.innerWidth > 1023) {
                disableScroll.off()
              } else {
                document.body.style.overflow = 'auto'
              }
              setModalStatus(false)
            }}
          />
          <Navigation menuItems={menuItems} modalStatus={modalStatus} />
        </Modal>

        {IBEID && variant === 'default' ? (
          <Media greaterThanOrEqual="desktopSmall">
            {typeof window !== 'undefined' && !isbot(navigator.userAgent) ? (
              <Syncrobox
                IBEID={IBEID}
                languageCode={languageCode}
                languagePrefix={languagePrefix || 'IT'}
              />
            ) : null}
          </Media>
        ) : null}

        <Media lessThan="desktopSmall">
          <Toolbars />
        </Media>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
`

const HeaderFixedStyle = css`
  background: ${theme.colors.variants.neutralLight4};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  .hamburger-menu {
    margin-top: 0;
    span {
      color: ${theme.colors.variants.neutralDark3};
    }
    .hamburger-lines {
      div {
        background: ${theme.colors.variants.neutralDark3};
      }
    }
  }
  .brand {
    padding: 1.25rem 2.5rem;
    img {
      height: 2.5rem;
    }
  }

  @media (max-width: 767px) {
    .brand {
      background: none;
      padding: 1.25rem 0;
    }
  }
`

const Head = styled(FlexBox)<HeadProps>`
  width: 100%;
  height: 5rem;
  position: relative;
  &.fixed {
    animation: fadeInTop 0.3s;

    ${HeaderFixedStyle}

    ${({ variant }) => {
      switch (variant) {
        case 'simple':
          return css`
            animation: none !important;
          `
      }
    }}
  }

  @media (max-width: 1199px) {
    animation: none !important;

    ${HeaderFixedStyle}
  }

  @keyframes fadeInTop {
    0% {
      top: -5rem;
    }
    100% {
      top: 0;
    }
  }
`

const Hamburger = styled(FlexBox)`
  cursor: pointer;
  margin: 2.375rem 0 0 3rem;
  position: relative;
  transition: 0.3s ease-in-out;
  &:hover {
    .hamburger-lines {
      div {
        &:nth-of-type(2) {
          width: 50%;
        }
      }
    }
  }

  @media (max-width: 1023px) {
    margin-left: 1.875rem;
  }
`

const HamburgerLines = styled.div`
  width: 2.25rem;
`

const HamburgerLine = styled.div`
  width: 100%;
  height: 0.125rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  border-radius: 1.25rem;
  transition: 0.3s ease-in-out;
  &:nth-of-type(2) {
    margin: 0.5625rem 0;
  }
`

const HambugerLabel = styled.span`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.0875rem;
  line-height: 1.25rem;
  margin-left: 1rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    display: none;
  }
`

const Brand = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  margin: auto;
  padding: 1.6875rem 1.875rem;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
`

const Logo = styled.img`
  width: auto;
  height: 2.875rem;
`

const IBECTA = styled(IBEAnchor)`
  display: flex;
  align-items: center;
  height: 5rem;
  background: ${({ theme }) => theme.colors.variants.neutralDark4};
  padding: 0 4.875rem;
  &:hover {
    span {
      &:after {
        right: -0.375rem;
        left: -0.375rem;
      }
    }
  }

  span {
    color: ${({ theme }) => theme.colors.variants.neutralLight4};
    font-family: ${({ theme }) => theme.fontFamily.paragraph};
    font-size: 0.875rem;
    font-weight: 700;
    letter-spacing: 0.0875rem;
    line-height: 1.25rem;
    position: relative;
    text-transform: uppercase;
    &:after {
      content: '';
      height: 0.125rem;
      background: ${({ theme }) => theme.colors.variants.neutralLight4};
      position: absolute;
      bottom: -0.375rem;
      right: 50%;
      left: 50%;
      transition: 0.3s ease-in-out;
    }
  }

  @media (max-width: 1199px) {
    display: none;
  }
`

const Modal = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: -1;
  transition: 0.2s ease-out;
  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 99;
  }
`

interface HeadProps {
  variant: Variant
}

export type Variant = 'default' | 'compact' | 'simple' | 'noibe'
